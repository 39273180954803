import { settings } from "../settings";
import { IInvoiceState } from "../state/state";
import { AuthContextType } from "../store/auth/auth.context";

const API = settings().INVOICE_API;

/**
 * Encapsulates the structure of the URL of a default project logo.
 * @param logoURL 
 * @returns True if its a URL for a default project logo
 */
export const isDefaultProjectLogo = (logoURL: string) => {
  return logoURL.includes('logo/project') || logoURL.includes('logo\\project');
};

/**
 * Encapsulates the logic of idempotently turning an absolute or relative URL into an absolute URL.
 * 
 * FIXME: Mixed absolute/relative logo paths.
 * @param logoURL 
 * @returns 
 */
export const toAbsoluteLogoURL = (logoURL: string): string => {
  return `${settings().PUBLIC_DIR}/${logoURL}`;
};

export const getInvoiceEndpoints = (auth: AuthContextType, state: IInvoiceState) => {
  const { logoId, logoURL } = state.InvoiceHeader;

  return {
    authHeaders: auth.isAuthenticated ? { Authorization: `Bearer ${auth.accessToken}` } : undefined,
    delete: logoId && logoURL && !isDefaultProjectLogo(logoURL)
      ? `${API}/logo/${encodeURIComponent(logoId)}` as const
      : undefined,
    upload: `${API}/logo`,
  } as const;
};

export const getProjectLogoEndpoint = (projectId: string) => {
  return `${API}/projects/project/logo/${projectId ? encodeURIComponent(projectId) : ''}` as const;
};

export const getProjectEndpoints = (auth: AuthContextType, projectId?: string) => {
  return {
    authHeaders: { Authorization: `Bearer ${auth.accessToken}` },
    upload: `${API}/projects/project/logo/${projectId ? encodeURIComponent(projectId) : ''}`,
    delete: `${API}/projects/project/logo/${projectId ? encodeURIComponent(projectId) : ''}`,
  } as const;
};