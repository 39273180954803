import { useState, useEffect, useReducer, useRef } from "react";
import { Skeleton, message } from "antd";
import { ActionTypes } from "../../state/actions";
import { IBreadcrumbItemProps } from "../../Components/shared/breadcrumb/breadcrumb-item/breadcrumb-item.component";
import reducer from "../../state/reducer";
import invoiceService from "../../Services/invoice.service";
import { IInvoiceState, getInitialState } from "../../state/state";
import { useAuthContext } from "../../store/auth/auth.context";
import { useLocation, useNavigate, useParams } from "react-router";
import { useAutoSave } from "./auto-save.hook";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../store/user/user.context";

const useInvoice = () => {
  const { t, i18n } = useTranslation();
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useAuthContext();
  const userContext = useUserContext();

  const localData = invoiceService.getLocalData();
  const initialState = authContext.isGuest && localData ? localData : getInitialState(t);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setLoading] = useState<boolean>(!authContext.isGuest);
  const [isSaving, setSaving] = useState<boolean>(false);
  const isStateChanging = useRef(false);
  useAutoSave({ state, isStateChanging, saveInvoice, setSaving });

  const fetchInvoiceData = async () => {
    setLoading(true);
    const invoiceData = await invoiceService.getInvoice(invoiceId!)
      .then(invoiceService.convertInvoiceDtoToState)
      .catch(() => {
        message.error(t('messages.invoice_fetch_error'));
        navigate('/');
        return null;
      })
      .finally(() => (
        setLoading(false)
      ));

    if (invoiceData) {
      dispatch({
        type: ActionTypes.UPDATE_STATE,
        payload: invoiceData,
        skipAutoSave: true
      });
    }
  };

  const applyInvoiceDefaults = async (invoice: IInvoiceState, projectId?: string) => {
    let project = (
      projectId
        ? userContext.projects.value.find(project => project._id === projectId)
        : userContext.projects?.defaultProjectRef
    );

    if (!project) {
      return;
    }

    const appliedInvoice = invoiceService.getAppliedInvoiceDefaults(invoice, project);

    dispatch({
      type: ActionTypes.UPDATE_STATE,
      payload: appliedInvoice,
      skipAutoSave: false
    });
  };

  const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
    if (isStateChanging.current) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  async function saveInvoice(invoice?: IInvoiceState) {
    setSaving(true);
    try {
      await invoiceService.saveInvoiceData(invoiceId, invoice ?? state);
    } catch (e) {
      message.error(t('messages.invoice_save_error'));
    } finally {
      setSaving(false);
      setLoading(false);
      isStateChanging.current = false;
    }
  };

  const getInvoiceBreadcrumbItems = (): IBreadcrumbItemProps[] => {
    const projectId = location.state?.projectId ?? state.InvoiceRightRail.project;
    let project = userContext.projects.value.find(project => project._id === projectId);

    if (location.state?.projectId) {
      navigate(location.pathname, { replace: true, state: { ...location.state, projectId: null } });
    }

    const breadcrumbItems: IBreadcrumbItemProps[] = [
      {
        title: 'Projects',
        route: '/projects'
      },
      {
        title: project?.name || <Skeleton.Input size="small" active />,
        route: project?.name ? `/projects/invoices/${projectId}` : undefined
      },
      {
        title: invoiceId,
      },
    ];

    return breadcrumbItems;
  };

  useEffect(() => {
    if (authContext.isGuest) {
      navigate('/', { replace: true });
      return;
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => (
      window.removeEventListener('beforeunload', handleBeforeUnload)
    );
  }, []);

  useEffect(() => {
    const projectsLoaded = !!userContext.projects.value.length;

    if (authContext.isGuest || !projectsLoaded) return;

    const fetchFunction = invoiceId ? fetchInvoiceData : applyInvoiceDefaults.bind(state);

    fetchFunction(state).finally(() => setLoading(false));
  }, [userContext.projects.value]);

  useEffect(() => {
    const isUserAction = state.skipAutoSave === false;

    if (isUserAction) {
      const projectId = state.InvoiceRightRail.project;

      applyInvoiceDefaults(state, projectId);
    }
  }, [state.InvoiceRightRail.project]);

  return {
    state: { value: state, dispatch },
    states: {
      isLoading: { value: isLoading, set: setLoading },
      isSaving: { value: isSaving, set: setSaving }
    },
    contexts: {
      authContext
    },
    invoiceHandlers: {
      saveInvoice,
      getInvoiceBreadcrumbItems
    },
    i18n,
    t
  };
};

export default useInvoice;
